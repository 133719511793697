<template>
  <div class="qr-code-kit" id="qr-code">
      <div class="container">
            <h1 data-aos="fade-up">QR Code Kit</h1>
            <p data-aos="fade-up" class="qr-text">Linkmeup QR code kit empowers your staff with smart business cards, identity cards, stickers, and smart tabletops to increase their productivity and also reduce overhead costs on business materials.</p>
            <!-- <ul>
                <li>Linkmeup Individual API docs for businesses</li>
                <li>Linkmeup Security API docs for businesses</li>
                <li>Business that Linkmeup API docs support</li>
            </ul> -->
            <div class="section2" data-aos="fade-up">
            <div class="info flex-align" data-aos="fade-left">
                <div class="write-up">
                    <h3>Linkmeup Business Cards</h3>
                    <div>
                        It is a two-way contact-sharing business card that allows you to seamlessly exchange your contact information with others and instantly receive theirs without having to manually write on paper, type on phones, or call out your details.
                    </div>
                </div>

                <div class="image-holder">
                    <img src="../../../assets/images/q1.png" alt="personal">
                </div>
            </div>

            <div class="info flex-align" data-aos="fade-right">
                <div class="image-holder">
                    <img src="../../../assets/images/q2.png" alt="personal">
                </div>

                <div class="write-up">
                    <h3>Linkmeup Identity cards</h3>
                    <div>
                        Linkmeup identity card is designed for people to verify the identity of your staff. It is time to prevent ghost workers and fraudsters from using a fake identity of your business brand to exploit you and other innocent citizens.
                    </div>
                </div>
            </div>

            <div class="info flex-align" data-aos="fade-left">
                <div class="write-up">
                    <h3>Linkmeup Doorbell Stickers</h3>
                    <div>
                        Linkmeup Doorbell Stickers enables you to identify who is knocking at your door where ever you are so you never miss a visit. Consider Linkmeup security stickers your new doorbell. 
                    </div>
                </div>

                <div class="image-holder">
                    <img src="../../../assets/images/q3.png" alt="personal">
                </div>
            </div>

            <div class="info flex-align" data-aos="fade-right">
                <div class="image-holder">
                    <img src="../../../assets/images/q4.png" alt="personal">
                </div>

                <div class="write-up">
                    <h3>Linkmeup Smart Tabletops</h3>
                    <div>
                        Linkmeup smart tabletop is the best way for your receptionist to identify and confirm meeting appointments of guests at the reception area. So, don’t keep your guests waiting rather make them feel special and important with Linkmeup. </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
h1, h2, h3, h4, h5 {
        color: #159661;
    }

    .qr-code-kit {
        min-height: 100vh;
        padding: 2rem 0;
    }

     h1 {
        font-weight: 800;
        font-size: 2rem;
    }

    h1, .qr-text {
        text-align: center;
        margin-bottom: 20px;
    }

    .qr-text {
        font-size: 14px;
        width: 80%;
        margin: 0 auto;
        color: #464646;
        margin-bottom: 2rem;
    }
.section2 h2 {
        font-weight: 800;
        font-size: 2rem;
    }

    .section2 h2, p {
        text-align: center;
        margin-bottom: 20px;
    }

    .section2 p {
        font-size: 14px;
        width: 30%;
        margin: 0 auto;
        color: #464646;
        margin-bottom: 2rem;
    }

    .info > div {
        flex: 1;
        font-size: 14px;
    }

    .info h3 {
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    .info .write-up {
        padding: 0 5rem;
    }

    .info img {
        width: 100%;
    }

    @media(max-width: 900px) {
        .section1 {
            flex-direction: column;
        }

        .section1 .write-up {
            width: 100%;
        }

        .section2 p {
            width: 97%;
            padding: 10px;
        }

        .info {
            flex-direction: column;
            margin-bottom: 3rem;
        }

        .info .image-holder {
            order: 1;
            text-align: center;
            margin-bottom: 3rem;
        }

        .info .image-holder img {
            width: 80%;
            margin: 0 auto;
        }

        .info .write-up {
            order: 2;
            padding: 10px;
            width: 100%;
        }
    }
</style>
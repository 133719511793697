<template>
  <section class="product">
      <div class="container">
        <h1>Your centralized Identity and Access Control Management solution</h1>

        <div class="section section1 flex-align" data-aos="fade-up">
            <div class="image-holder">
                <img src="../../../assets/images/s0.png" alt="app-photo">
            </div>

            <div class="write-up" data-aos="fade-left">
                <!-- <p>
                    Unwanted persons are the cause of insecurity that we face in our environment, as they intrude on our privacy and property without permission, sometimes they visit with false claims just to gain access to our environment to cause nuisance, threat to life and property.
                </p>
                <p>With Linkmeup, identity is security as you can easily identify unwanted persons with their full information, block them from ever gaining access into your property, or even report them to the police.</p> -->
                <p>
                    Linkmeup security is a multifunctional application that provides a robust end-to-end access control and security management system.  
                </p>
                <p>
                    It is built to tackle insecurity by  identifying and restricting access of unwanted persons from gaining entry into your homes, schools, businesses and communities keeping you and your property safe. 
                </p>
            </div>
        </div>

        <div class="section2" data-aos="fade-up">
            <h2>Features</h2>
            <!-- <p>Linkmeup security is a security solution that is extremely easy to set up for use on your mobile phone, laptops, and PCs, it comes with loads of security features to keep you, your privacy, and your property safe.</p> -->
            <div class="info flex-align" data-aos="fade-left">
                <div class="write-up">
                    <h3>Identification</h3>
                    <div>
                        With Linkmeup security you are able  to identify who’s paying you a visit thereby eliminating the anonymity of strangers so you can feel safe knowing the identity of those visiting
                    </div>
                </div>

                <div class="image-holder">
                    <img src="../../../assets/images/s1.png" alt="personal">
                </div>
            </div>

            <div class="info flex-align" data-aos="fade-right">
                <div class="image-holder">
                    <img src="../../../assets/images/s2.png" alt="personal">
                </div>

                <div class="write-up">
                    <h3>Invitation and Appointment Confirmation</h3>
                    <div>
                        It helps your security, doorman, and receptionist to discard unsolicited guests while giving quick entry access to those with confirmed appointments once they arrive to avoid queues and crowded reception areas.
                    </div>
                </div>
            </div>

            <div class="info flex-align" data-aos="fade-left">
                <div class="write-up">
                    <h3>Entry records</h3>
                    <div>
                        It is time to say goodbye to visitors' books as Linkmeup eliminates the orthodox way of visitors recording their information on paper which offers poor durability and data retrieval. With Linkmeup you can instantly capture, trace, retrieve, view history, and export guest entry activities in your environment.
                    </div>
                </div>

                <div class="image-holder">
                    <img src="../../../assets/images/s3.png" alt="personal">
                </div>
            </div>

            <div class="info flex-align" data-aos="fade-right">
                <div class="image-holder">
                    <img src="../../../assets/images/s4.png" alt="personal">
                </div>

                <div class="write-up">
                    <h3>Clock in and clock out</h3>
                    <div>
                        Linkmeup provides a clock-in system, which allows staff, workers, and students to effortlessly clock in and clock out for proper attendance records.
                    </div>
                </div>
                </div>
            </div>

            <div class="info flex-align" data-aos="fade-left">
                <div class="write-up">
                    <h3>Access Control</h3>
                    <div>
                        Linkmeup security enables you to block unwanted persons from gaining access to your premises. Be in total control of who goes in and out of your environment.
                    </div>
                </div>

                <div class="image-holder">
                    <img src="../../../assets/images/newS5.png" alt="personal">
                </div>
            </div>
        </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
    /* Global styles for this component  */
    h1, h2, h3, h4, h5 {
        color: #159661;
    }

    .section1 img {
        width: 100%;
    }

    .section1 .image-holder {
        margin-right: 2.5rem;
    }

    .section1 > div {
        flex: 1;
    }

    .section1 {
        /* justify-content: center; */
        margin-bottom: 4.5rem;
        margin-top: 4.5rem;
        /* text-align: ce; */
    }


    .section1 .write-up {
        width: 50%;
        font-size: 14px;
        padding: 1rem 3rem;
        text-align: left;
    }

    .section1 p {
        margin-bottom: 20px;
    }

    h1 {
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 4.5rem;
        padding-top: 3rem;
        width: 60%;
        margin: 0 auto;
    }

    .section2 h2 {
        font-weight: 800;
        font-size: 2rem;
    }

    .section2 h2, .section2 p {
        text-align: center;
        margin-bottom: 20px;
        /* padding-top: 3rem; */
    }

    .section2 p {
        font-size: 14px;
        width: 80%;
        margin: 0 auto;
        color: #464646;
        margin-bottom: 2rem;
    }

    .info > div {
        flex: 1;
        font-size: 14px;
    }

    .info h3 {
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    .info .write-up {
        padding: 0 5rem;
    }

    .info img {
        width: 100%;
    }

    @media(max-width: 900px) {
        .section1 {
            flex-direction: column;
        }

        .section1 .write-up {
            width: 100%;
        }

        .section2 p {
            width: 97%;
            padding: 10px;
        }

        .info {
            flex-direction: column;
            margin-bottom: 3rem;
        }

        .info .image-holder {
            order: 1;
            text-align: center;
            margin-bottom: 3rem;
        }

        .info .image-holder img {
            width: 80%;
            margin: 0 auto;
        }

        .info .write-up {
            order: 2;
            padding: 10px;
            width: 100%;
        }

        h1 {
            width: 100%;
            font-size: 2rem;
        }
    }
</style>
<template>
  <div class="home">
    <div class="mobile-nav">
      <MobileNav :showNav="showNav" v-on:close-nav="showNav = false" :type="2"/>
    </div>
    <Header :type="2" v-on:open-mobile-nav="showNav = !showNav"/>
    <div class="login-form">
      <router-view></router-view>          
    </div>
    <Showcase/>
    <Product/>
    <QRCodeKit/>
    <Pricing/>
    <Feedback/>
    <Footer/>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import MobileNav from '../../components/MobileNav.vue' 
import Showcase from './components/Showcase.vue'
import Product from './components/Product.vue'
import QRCodeKit from './components/QRCodeKit.vue'
import Pricing from './components/Pricing.vue'
import Feedback from './components/Feedback.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    MobileNav,
    Showcase,
    Product,
    QRCodeKit,
    Pricing,
    Feedback,
    Footer
  },
  data(){
    return {
      showNav: false
    }
  },
  mounted(){
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/629a8a5ab0d10b6f3e759ab0/1g4lqosqt';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
  }
}
</script>



<style scoped>
  h1, h2, h3, h4, h5, h6 {
    /* font-family: 'Lato', sans-serif; */
  }

  .login-form {
      position: absolute;
      background-color: #fff;
      box-shadow: 0 0 6px 0 #3f3e3e;
      margin-top: 5px;
      width: 500px;
      right: 10px;
      border-radius: 20px;
      color: #000;
      z-index: 100;

  }

  @media(max-width: 769px) {
    .login-form {
        width: 97%;
        margin: 0 auto;
        left: 5px;
        box-shadow: 0 0 6px 0 #a3a3a3;
        z-index:10000000000;
        top: 20px;
    }
  }
</style>

<template>
  <div class="feedback">
        <div class="container">
            <div class="action  flex-center" data-aos="fade-right">
                <h1>Got any Questions?</h1>
                <p>Reach out to us. +1 (302) 440-3393</p>

                <div class="form flex-align" data-aos="fade-left">
                    <input type="email" placeholder="Enter your email">
                    <button>Contact us</button>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .feedback {
        height: 300px;
        background: url('../../../assets/images/secondary.png');
        background-size: cover;
        color: #fff;
        margin-bottom: 4rem;
    }
    
    .action {
        flex-direction: column;
        height: 300px;
    }

    .action div, .action p, .action h1 {
        margin-bottom: 20px;
    }

    input {
        padding: 10px;
        border: none;
        border-radius: 5px;
        outline: none;
        width: 300px;
        margin-right: 20px;
    }

    button {
        padding: 10px;
        border: none;
        border-radius: 5px;
        color: #111;
        background-color: #fff;
    }

    @media(max-width: 700px) {
        .form {
            flex-direction: column;
        }

        .form input {
            margin-bottom: 10px;
        }
    }

    @media(max-width: 500px) {
        .feedback p {
            font-size: 14px;
        }
    }
</style>
<template>
  <div class="pricing" id="subscription">
      <div class="container">
          <h1 data-aos="fade-right">Subscriptions and pricing</h1>
          <div class="plans flex-align">
              <div class="home" data-aos="fade-left" data-aos-duration="2000">
                  <h2>Home</h2>
                  <div class="free flex-center">
                      <h3>Basic</h3>
                      <p>Free for lifetime</p>
                      <h1 class="price"><span class="currency">$</span>0</h1>
                  </div>
                  <!-- <div class="standard flex-center">
                    <h3>Standard</h3>
                    <h1 class="price"><span class="currency">$</span>100</h1>
                  </div> -->
                  <div class="premium flex-center">
                      <h3>Premium</h3>
                      <h1 class="price"><span class="currency">$</span>50/Month</h1>
                  </div>
              </div>
              <div class="home" data-aos="fade-up" data-aos-duration="2000">
                  <h2>Estate</h2>
                  <div class="free flex-center">
                      <h3>Basic</h3>
                      <h1 class="price"><span class="currency">$</span>100/Month</h1>
                  </div>
                  <!-- <div class="standard flex-center">
                    <h3>Standard</h3>
                    <h1 class="price"><span class="currency">$</span>100</h1>
                  </div> -->
                  <div class="premium flex-center">
                      <h3>Premium</h3>
                      <h1 class="price"><span class="currency">$</span>125/Month</h1>
                  </div>
              </div>
              <div class="business" data-aos="fade-up" data-aos-duration="1000">
                <h2>Business</h2>
                <div class="free flex-center">
                    <h3>Basic</h3>
                    <h1 class="price"><span class="currency">$</span>100/Month</h1>
                </div>
                <!-- <div class="standard flex-center">
                <h3>Standard</h3>
                <h1 class="price"><span class="currency">$</span>100</h1>
                </div> -->
                <div class="premium flex-center">
                    <h3>Premium</h3>
                    <h1 class="price"><span class="currency">$</span>150/Month</h1>
                </div>
              </div>
              <div class="school" data-aos="fade-right" data-aos-duration="2000">
                <h2>School</h2>
                <div class="free flex-center">
                    <h3>Basic</h3>
                    <h1 class="price"><span class="currency">$</span>100/Month</h1>
                </div>
                <!-- <div class="standard flex-center">
                <h3>Standard</h3>
                <h1 class="price"><span class="currency">$</span>100</h1>
                </div> -->
                <div class="premium flex-center">
                    <h3>Premium</h3>
                    <h1 class="price"><span class="currency">$</span>175/Month</h1>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .pricing {
        margin-bottom: 7rem;
        padding-top: 2rem;
    }

    .container > h1 {
        color: #159661;
        text-align: center;
        margin-bottom: 30px;
    }
    
    /* .plans > div:first-child {
        background: transparent;
    } */

    .plans {
        width: 88%;
        margin: 0 auto;
    }

    .plans > div {
        text-align: center;
        width: 90%;
    }

    .plans div h2 {
        font-size: 20px;
        color: #3f3f3f;
        margin-bottom: 20px;
    }

    .plans > div > div {
        text-align: center;
        padding: 20px 1rem;
        background: linear-gradient(#4bd69c, #159661);
        color: #fff;
        width: 95%;
        margin-bottom: 20px;
        border-radius: 10px;
        flex-direction: column;
        height: 200px;
    }

    .plans > div h3 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .plans > div p {
        font-size: 12px;
        margin-bottom: 20px;
        color: #f2f1f1d4;
    }

    .price {
        position: relative;
        font-size: 2rem !important;
        /* width: 60px; */
        /* margin: 0 auto; */
    }

    .currency {
        font-size: 15px;
        position: absolute;
        top: 5px;
        left: -8px;
        /* margin-right: 10px; */
        /* padding-right: 30px; */
    }

    @media(max-width: 768px) {
        .flex-align {
            flex-direction: column;
        }
    }
</style>